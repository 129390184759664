a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
  }
  
  html {
    font-size: 140px;
  }
  @media (min-width: 768px) {
    html {
      font-size: 16px;
    }
  }
  
  .box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
  }
  

  .modalFooter{
      position:fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    padding: 20px;
  }


  .modalFooter button{
     margin-right: 2;
}

.nav-logo{
width: 250px;
height: 27px;
  /* style={{ width: "255px", height: "27px" }} */
}
  .footer {
    position:fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    color: #6c7592;
    text-align: center;
    
  }
.form-number-input input{
  text-align: right !important;
}


.dropdown-toggle{
  background-color: #F8F9FA;
  color: #4a4a4b;
  border-width: 0;
}

.profile-left-column{
  left: 12px;
}

