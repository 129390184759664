/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}
.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.error-class{
  border-width: 1px;
  border-color: red;
}
.error-page{
text-align: center;
}

.placeOrderActions button{
  margin-right: 5;
}

.footer{
  position: fixed;
  Width: 100%;
  bottom: 0;
}
.ui.segment.place.order{
  height: 468px;
}


 